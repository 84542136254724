import _ from 'lodash'
import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import dayjs from 'dayjs'
import { app } from "@/main"
import { PROPERTY_CODE } from '@/constants'

const expire = window.localStorage.getItem('expire_in');
const token = () => {
  const token = window.localStorage.getItem('access_token');
  const tokenType = window.localStorage.getItem('token_type');
  return token ? { Authorization: `${tokenType} ${token}` } : {}
};

const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  //timeout: 10000,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...token()
  },
  transformRequest: (data, heads) => {
    const fd = data instanceof FormData;
    heads['Content-Type'] = fd ? 'multipart/form-data' : 'application/json';
    return fd ? data : JSON.stringify(data || '{}')
  }
});

const refreshTokenRequest = async (originalRequest) => {
  try {
    const refreshToken = localStorage.getItem('refresh_token');
    delete http.defaults.headers["Authorization"];
    const res = await http.post('user/auth/refresh', { refresh_token: refreshToken });
    Vue.prototype.$auth.login(
      res.access_token, res.refresh_token, res.expires_in, res.token_type
    );
    if (originalRequest) {
      originalRequest.headers["Authorization"] = `${res.token_type} ${res.access_token}`;
      originalRequest.data = JSON.parse(originalRequest.data);
      return http(originalRequest)
    }
    return res.status
  } catch (error) {
    Vue.prototype.$auth.logout();
    Vue.prototype.$msg('Your session expired, please sign in!')
  }
};

const fetchCartToken = async (originalRequest) => {
  try {
    const res = await http.post(`cart/token`, { property_code: PROPERTY_CODE });

    if (res.cart_token) {
      await store.dispatch('property/setCartToken', res.cart_token)

      if (originalRequest) {
        let url = originalRequest.url;
        url = url.split("/");
        url.pop();
        url.push(res.cart_token);
        url = url.join("/");
        originalRequest.url = url;
        return http(originalRequest)
      }
    }

    return res.status
  } catch (error) {
    //console.log("fetchCartToken", error)
  }
};

if (expire && parseFloat(expire) < Date.now()) {
  refreshTokenRequest()
}

http.interceptors.response.use(
  (response) => {
    response?.data?.debug && Vue.prototype.$debug(response.data.debug);

    if (response?.data?.debug) {
      console.log('response', response)
      store.dispatch('app/setDebugResponse', { ...response?.data?.debug, url: response.config.url })
    }

    return response.data
  },
  (error) => {
    // TOO MANY REQUESTS
    if (error.response?.status === 429) {
      const retryAfter = _.chain(error.response.headers)
        .get('retry-after')
        .toNumber()
        .value()

      store.dispatch('app/setError429RetryAfter', dayjs().unix() + retryAfter)
    }

    const originalRequest = error.config;
    console.log('error', error)
    error?.response?.data?.debug && Vue.prototype.$debug(error.response.data.debug);

    if (error?.response?.data?.debug) {
      store.dispatch('app/setDebugResponse', { ...error?.response?.data?.debug, url: error.config.url })
    }

    if (!error.response) {
      Vue.prototype.$msg('Network Error occurred, please check your connection or try again later!')
    } else if (error.response.status === 400) {
      Vue.prototype.$auth.logout()
    } else if (error.response.status === 401) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!originalRequest._retry && refreshToken) {
        originalRequest._retry = true;
        return refreshTokenRequest(originalRequest)
      } else if (error.response.config && error.response.config.silent) {
        //
      } else {
        Vue.prototype.$msg(error.response.data.detail);
        Vue.prototype.$auth.logout()
      }
    } else if (error.response.status === 422) {
      if (!error.response.data.validation_messages) {
        if (
          error.response.data.detail !== 'Rateplan details not available' &&
          // not cart items expired error
          !['EXPIRED', 'REVIEW'].includes(error.response.data.result)
        ) {
          // app.$bvToast.toast(error.response.data.detail, {
          //   title: 'Error',
          //   variant: 'danger',
          //   solid: true
          // })

          // @TODO move all options to bootstrap settings
          app.$bvModal.msgBoxConfirm(error.response.data.detail, {
            title: 'Error',
            headerBgVariant: 'secondary',
            headerClass: 'close-button-black',
            titleClass: 'text-white text-uppercase font-family-2 line-heigt-10',
            contentClass: 'bg-gamma ok-only',
            bodyClass: 'font-size-14 line-height-11 font-weight-500 py-4',
            footerClass: 'border-0',
            okTitle: 'Ok',
            okVariant: 'primary',
            hideHeaderClose: false,
            cancelVariant: 'outline-primary',
            buttonSize: 'lg',
            centered: true
          })
        }
      }
      Vue.prototype.$setErrors(error.response.data.validation_messages);

      /*
      // check if cart_token is an issue and fix it
      if (error.response.data.validation_messages && error.response.data.validation_messages.cart_token) {
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          return fetchCartToken(originalRequest)
        }
      }
      */
      throw error
    }

    throw (error.response?.data)
  });

export default http
